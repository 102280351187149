
import React from 'react';
import i18n from '../../i18n';
import { Dropdown } from 'react-bootstrap';
import { navigate } from "gatsby"
import Language from './language';

//import './languageselector.scss';
/**
 * Component for displaying a hardcoded list of languages and allowing the user to change them.
 */
class LanguageSelector extends React.Component {
  constructor(props) {
    super(props);
    this.changeLanguage = this.changeLanguage.bind(this);

    if (i18n.language.length > 2) {
      this.state = {
        language: i18n.language.substring(0,2)
      }
    } else {
      this.state = {
        language: i18n.language
      }
    }

  }

  /**
   * Will be called by the Language sub-component.
   */
  changeLanguage(lng) {
    i18n.changeLanguage(lng);
    let nav_target = '/' + lng + '/' + this.props.location.pathname.substring(4);
    navigate(nav_target);
  }

  render() {

    let lng = i18n.language;

    if (lng.length > 2) {
      lng = lng.substring(0,2);
    }
    let lng_string = lng;
    let languages = [{ key: 'en', text: 'English', value: 'en' },
    //{ key: 'French', text: 'French', value: 'French' },
    { key: 'de', text: 'Deutsch', value: 'de' },
    ];

    for (let i = 0; i < languages.length; i++) {
      if (languages[i].key === lng) {
        lng_string = languages[i].text;
        languages.splice(i,1);
      }
    }
    let lng_nodes = languages.map(lng => {
      return (
        <Language language={lng} key={lng} onClick={this.changeLanguage}/>
      )}
    )

    return (
      <Dropdown className={this.props.className} id="languageselector">
        <Dropdown.Toggle variant="link">{lng_string}</Dropdown.Toggle>
        <Dropdown.Menu>{lng_nodes}</Dropdown.Menu>
      </Dropdown>
      )
  }
}

export default LanguageSelector;