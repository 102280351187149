import React from 'react';

class HeadingComponent extends React.Component {

  render() {

    // we want to remove the "as" prop, because it is justed needed internally.
    let propsCopy = Object.assign({}, this.props);
    delete propsCopy.as;
    delete propsCopy.type;

    switch (this.props.as.toLowerCase()) {
      case "h1":
        return (<h1 {...propsCopy}>{this.props.children}</h1>)
      case "h2":
        return (<h2 {...propsCopy}>{this.props.children}</h2>)
      case "h3":
        return (<h3 {...propsCopy}>{this.props.children}</h3>)
      case "h4":
        return (<h4 {...propsCopy}>{this.props.children}</h4>)
      case "h5":
        return (<h5 {...propsCopy}>{this.props.children}</h5>)
      case "h6":
        return (<h6 {...propsCopy}>{this.props.children}</h6>)
      default:
        return (<h6 {...propsCopy}>{this.props.children}</h6>)

    }
  }

}

HeadingComponent.defaultProps = {
  as: 'h6'
}

export default HeadingComponent;