import React from "react"
//import { Trans, translate } from 'react-i18next';
import LanguageSelector from '../components/navbar/languageselector/languageselector';
import NavLink from '../components/net/navlink';
import Link from '../components/net/link';

import { withTranslation } from 'react-i18next';
//import i18n from './i18n';

import { Navbar, Nav} from 'react-bootstrap';
import AppLauncher from '../components/navbar/applauncher/applauncher';
import { setSetting } from '../common/store';

//import './header.scss';

class Header extends React.Component {

  componentDidMount() {

  }

  render() {
    const { t } = this.props;
    //check language, and if the i18n selected language doesn't match up with the 
    //path start, we redirect. do this in componentdidmount.
    
    let pathLng = this.props.location.pathname.substring(1,3);
    if (this.props.i18n.language !== pathLng) {
      this.props.i18n.changeLanguage(pathLng);
      //this.forceUpdate();
    }

    setSetting('entrypoint', this.props.location.href);


    return (
      <header>
        <Navbar expand="lg" variant={this.props.variant}>
          <Navbar.Brand><Link to='/' className="navbar-brand">nutrfy</Link></Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />

  <Navbar.Collapse id="responsive-navbar-nav">
      <Nav className="mr-auto">  
      <NavLink href="/faq">{t("header.FAQ")}</NavLink>
      <NavLink href="/why-nutrfy/">{t("header.Why")}</NavLink>
      {
        /*

      <NavLink href="/features/">{t("header.Features")}</NavLink>
      <NavLink href="/benefits/">{t("header.Benefits")}</NavLink>
              */
    }
      <NavLink href="/knowledge/">{t("header.Knowledge")}</NavLink>
      <NavLink href="/tools/">{t("header.Tools")}</NavLink>

      {/* <NavLink href="/help">{t("header.Help")}</NavLink> */ }
      {/* <NavLink href="/our-pledge/">{t("header.Our_Pledge")}</NavLink> */}
      <NavLink href="/pricing/">{t("header.Pricing")}</NavLink>
      
      </Nav>
      <LanguageSelector location={this.props.location} className="mr-sm-4"/>
      <AppLauncher />
      </Navbar.Collapse>
      

      {/*
        <Menu.Menu position='right'>
          <Menu.Item><LanguageSelector location={this.props.location} /></Menu.Item>
          <Menu.Item>Launch App</Menu.Item>
        </Menu.Menu>
         */}
        </Navbar>
      </header>

    )
  }
}

Header.defaultProps = {
  variant: "light"
}

export default withTranslation()(Header);
