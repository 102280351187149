import React from 'react';
import { Button } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
//import './applauncher.scss';

class AppLauncher extends React.Component {

  render() {
    return (
      <Button href="https://app.nutrfy.com" target="_blank" variant="outline-primary" id="app-launcher">{this.props.t('header.Launch_App')}</Button>
    )
  }
}

export default withTranslation()(AppLauncher);