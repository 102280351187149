import React from 'react';
import { Dropdown, Button } from 'react-bootstrap';

/**
 * 
 * Language entry at the language selector drop down.
 */
class Language extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }
  handleClick(event) {
    event.preventDefault();
    this.props.onClick(this.props.language.key);
  }

  render() {
    return (
      <Dropdown.Item as={Button} eventKey={this.props.language.key} onClick={this.handleClick}>{this.props.language.text}</Dropdown.Item>
    )
  }

}

export default Language;