import React from 'react';
import i18n from '../i18n';
import { Link } from 'gatsby';

class NavLink extends React.Component {
//data-rb-event-key
  render() {
    let lang = i18n.language.substring(0,2);
    let path = '/' + lang + this.props.href;
    return (
      <Link to={path} className="nav-link" data-rb-event-key={path}>{this.props.children}</Link>
    )
  }
}

export default NavLink;