import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import i18n from '../i18n';

class Link extends React.Component {

  render() {
    let lang = i18n.language.substring(0,2);
    let path = '/' + lang + this.props.to;
    let propsCopy = Object.assign({}, this.props);
    propsCopy.to = path;
    return (
      <GatsbyLink {...propsCopy}>{this.props.children}</GatsbyLink>
    )
  }
}

export default Link;