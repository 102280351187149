import React from 'react';
import Link from '../components/net/link';
import { withTranslation } from 'react-i18next';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import Heading from '../components/heading/heading';

import { navigate } from "gatsby"

import {setSetting } from '../common/store';
import Eff from '../components/Eff';

import i18n from '../components/i18n';
import CookieBar from '../components/cookiebar/cookiebar';
import Cookies from 'universal-cookie';

class footer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {email: ''};
    this.handleJoinClick = this.handleJoinClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCookieAccept = this.handleCookieAccept.bind(this);

  }
  handleCookieAccept() {
    this.forceUpdate(); // re-render to hide the cookie bar
  }

  handleJoinClick(event) {
    event.preventDefault();
    setSetting('joinemail', this.state.email);
    let lang = i18n.language.substring(0,2);

    navigate('/' + lang + '/join');
  }

  handleChange(event) {
    this.setState({[event.target.name]: event.target.value});
  }

  render() {
    const { t } = this.props;

    const cookies = new Cookies();
    let optin_cookie = cookies.get('optIn');

    //if we do it the other way round, we might get a 'ghost flash' of a render of the
    //cookie bar
    let show_cookiebar = false;
    if (optin_cookie !== 'true') {
      show_cookiebar = true;
    }

    return (
      <footer className="footer py-3">
        <Container>
          <Row>
            <Col lg={3} xs={6} className="order-md-1 order-2">
            <ul className="list-unstyled text-small">
              <li><Heading as="h5">{t("footer.about_us")}</Heading></li>
              <li><Link to="/about/" className="text-muted">{t("footer.about")}</Link></li>
              <li><Link to="/terms-and-conditions/" className="text-muted">{t("footer.terms_and_conditions")}</Link></li>
              <li><Link to="/privacy-statement/" className="text-muted">{t("footer.privacy_statement")}</Link></li>
              <li><Link to="/contact/" className="text-muted">{t("footer.contact")}</Link></li>
              <li><Link to="/impressum/" className="text-muted">{t("footer.impressum")}</Link></li>
              {/*<li><Link to="/our-pledge/" className="text-muted">{t("header.Our_Pledge")}</Link></li>*/}
              </ul>        
          </Col>

          <Col lg={3} xs={6} className="order-md-2 order-3">
          <ul className="list-unstyled text-small">
            <li><Heading as="h5">{t("footer.about_our_product")}</Heading></li>
              <li><Link to="/faq/" className="text-muted">{t("header.FAQ")}</Link></li>
              <li><Link to="/why-nutrfy/" className="text-muted">{t("header.Why")}</Link></li>
              {/*<li><Link to="/features/" className="text-muted">{t("header.Features")}</Link></li>*/}
              {/*<li><Link to="/benefits/" className="text-muted">{t("header.Benefits")}</Link></li>*/}
              {/* <li><Link to="/knowledge" className="text-muted">{t("header.Knowledge")}</Link></li> */}
              {/* <li><Link to="/help" className="text-muted">{t("header.Help")}</Link></li> */ }
              {/*<li><Link to="/integrations/" className="text-muted">{t("footer.integrations")}</Link></li>*/}
              <li><Link to="/knowledge/" className="text-muted">{t("header.Knowledge")}</Link></li>
              <li><Link to="/tools/" className="text-muted">{t("header.Tools")}</Link></li>
              <li><Link to="/pricing/" className="text-muted">{t("footer.pricing")}</Link></li>
              </ul>        
              </Col>
          <Col lg={4} className="order-md-3 order-1">
            <Heading as="h5">{t("footer.join_us")}</Heading>
            <div className="text-small text-muted">{t("footer.join_us_text")}</div>

            <Form className="mt-3 mb-3">
              <Button size="sm" variant="secondary"  href="https://app.nutrfy.com/signup" target="_blank">{t('joinform.join_now')}</Button>
            </Form>

          </Col>
          <Col lg={2} className="d-none d-lg-block order-lg-4">
          <a href="https://www.strava.com" target="_blank" rel="noopener noreferrer"><img className="float-right" src="/assets/external/strava/compatible_with_strava.png" width="130px" height="56px" alt="Strava"/></a>
          <span className="fwends px-3">
          <div className="text-small text-muted text-right">Nutrfy is a project made by Fwends.</div>
          <span id="logos" className="float-right mt-2">
          <svg height="36" width="10">
            <circle cx="5" cy="5" r="5"/>
            <circle cx="5" cy="18" r="5"/>
            <circle cx="5" cy="31" r="5"/>
          </svg>
          <Eff />
          </span>
</span>
          </Col>
          </Row>
          <Row className="d-lg-none">
            <Col>
            <a href="https://www.strava.com" target="_blank" rel="noopener noreferrer"><img src="/assets/external/strava/compatible_with_strava.png" width="130px" height="56px" alt="Strava"/></a>

            </Col>
          </Row>

        </Container>

        {show_cookiebar ? 
        <CookieBar onAccept={this.handleCookieAccept} />
        : null }

        </footer>
    )
  }
}

export default withTranslation()(footer);