import React from 'react';
import HeadingComponent from './heading-component';
//import './heading.scss';


/**
 * Component for rendering H1, H2, ...
 * 
 * Props:
 * as: HTML element to use (h1, h2, h3 ...)
 * type: set "display" for a bolder heading.
 * 
 */
class Heading extends React.Component {


  render() {

    //let className = this.props.className;


    if (this.props.type === "display" || this.props.type === "section") {
      let propsCopy = Object.assign({}, this.props);

      let className = this.props.type + "-" + this.props.as.substring(1,2);
      if (propsCopy.className === undefined) {
        propsCopy.className = className;
      } else {
        propsCopy.className = propsCopy.className + " " + className;
      }
      

      return <HeadingComponent {...propsCopy}><span className={this.props.type + "-inner"}>{this.props.children}</span></HeadingComponent>
    } else {
      return <HeadingComponent {...this.props}>{this.props.children}</HeadingComponent>
    }
    
  }

}


Heading.defaultProps = {
  as: "h6"
}

export default Heading;