import React from 'react';
//import './cookiebar.scss';
import { Container, Button } from 'react-bootstrap';
import Cookies from 'universal-cookie';
import Link from '../net/link';
import { withTranslation, Trans } from 'react-i18next';

class CookieBar extends React.Component {
  constructor(props) {
    super(props);
    this.handleAccept = this.handleAccept.bind(this);
  }


  handleAccept(event) {
    event.preventDefault();
    //console.log('Setting cookie');
    const cookies = new Cookies();
    cookies.set('optIn', 'true', {path: '/', maxAge: 31557600}); //one year expiration
    this.props.onAccept();
  }

  render() {
    const { t } = this.props;
    return (
      <div className="cookiebar">
        <Container>
        <div className="text-center text-small py-2"><Trans i18nKey='components.cookiebar_message'>We use cookies on this site. <Link to="/privacy-statement">Learn more.</Link></Trans>
        <Button variant="outline-light" className="ml-3" size="sm" onClick={this.handleAccept}>{t('components.cookiebar_accept')}</Button>
        
        </div>
        </Container>

      </div>
    )
  }
}

export default withTranslation()(CookieBar);