let settings = {};

function setSetting(name, value) {
  //special case for entrypoint: We set this only once.
  //it is done in header, because this component will ALWAYS be called.
  if (name === 'entrypoint') {
    if (settings.entrypoint === undefined) {
      settings.entrypoint = value;
    }
  } else {
    settings[name] = value;
  }
}

function getSetting(name) {
  return settings[name];
}

module.exports = {setSetting, getSetting};